import "../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA9VWwXLbIBC9+yu4dKY9kJFUJ3Xxx3SwQBIJAgIoktvpv3dAkgMxViynh/ai0QC77+3uY5e7H3nB7wv7nIFfGwDkC9UVlz0CDSOEiv0GAEsHC183KOdMGWbcVt8wS6FRuKQICNlrrPab35u72WnunRJmFMdHBCpOB2dWY4XATvl/zFktILO0NQiUVFiqIxdF2sVjZyyrjrCUwlJhEfAk4IHano60FSaEiRqBIlMDKLYj3EFqQjW0UiGQqwEYyRkBL1h/hhBXFRMUTkdKyaX+4mwcJnSxITBGOEWQF85nwPXrrVzfTUgQTv6ghvGzm34iDlvPwUEjkO9TdHw4hGlaWiYFAqXkXetp9IzYBoE8yz7tk2IIcO7TsQbAi+YPH1fGt/9IGbuxKlJYaNhPikA+ofqlnrK6sQhss8ytcYfVTGtFMR70oCjm42+moaUUBOtjwOsU4fYkkVsCDAL4HsiqWFbR5ITTyl6fyVkscAhbz2n1iADurIwo4XT9L9wfX5cpE1F6dkEh4tok6zDdkRYPs8IiUoeLbTSq9IOrdGBWerMTr2xmtaLqgTeyPjPb8ywUqSxk4+Li3aar4FMJBeCAy6day04QmMpBsK00a2P1p7I/Xsup/smxNQv3es1OCxoT1plT23orrjG1QXqq9dXJszUiDbDqi3JcMRbCpuxPmUYz8YRArOEm1rDTz+uwWgl57Rhh/8DEffzAxF0M7ikdXLK5zOiBOb88odNkZhWN8z/w1HpPLdY1E1NfHxtBcEb4M9ELItiVi7vK76bu+dvLHRg9n43UpYZ1WyPVfwdjIQpzdegzD3vkFAFmMWfl2VzZvZkr9qJ7VkqRItR5i85QDQ3ltLSuQwqa6HdTAyo7bZx3JdnZE61DjVOrd/lOR/cHU4Re4rbiXzRF9KKJGnBg2a/EhRXjnJIYfojhoydjMkuBWmZhxGrZ3vCgmyI9SGtle/Xz7Q/4psWU3Q0AAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottomButton = '_12l52tqv';
export var bottomLeft = '_12l52tq6';
export var button = '_12l52tqu';
export var collectionEditContainer = '_12l52tql';
export var confirmButton = '_12l52tqm';
export var ellipsis = '_12l52tq0';
export var icon = '_12l52tqt';
export var includeAddButton = '_12l52tqa';
export var includeItem = '_12l52tqe';
export var includeItemContent = '_12l52tqd';
export var includeItemContentIs = '_12l52tqc';
export var includeItemTitle = '_12l52tqb';
export var includeListTitle = '_12l52tq8';
export var includeTitle = '_12l52tqf';
export var pageList = '_12l52tqo';
export var pagesBottom = '_12l52tq2';
export var pagesBottomLeft = '_12l52tq1';
export var pagesList = '_12l52tq5';
export var pagesTab = '_12l52tq4';
export var pagesTabContent = '_12l52tq3';
export var previewActive = '_12l52tqw';
export var previewCountTips = '_12l52tqq';
export var previewCountTipsHighlight = '_12l52tqp';
export var resultPages = '_12l52tqn';
export var rulesBottom = '_12l52tq7';
export var rulesContainer = '_12l52tqk';
export var rulesContainerLeft = '_12l52tqj';
export var rulesContainerLeftContent = '_12l52tqh';
export var rulesContainerLeftContentInclude = '_12l52tqg';
export var rulesContainerLeftTab = '_12l52tqi';
export var rulesContainerRight = '_12l52tq9';
export var rulesTitle = '_12l52tqx';
export var rulesTitleHighlight = '_12l52tqs';
export var selectedCountTips = '_12l52tqr';