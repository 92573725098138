import "../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbW/aMBD+zq+wKlUqUo0CLYwZbf9jmqbJ2JfEq2NHtlPopv73yXYCTghlbJX2jRz38txzT+4y+z7noprvIUO/JghxYWtJXwjKJew3E4SYltoQ9EzNHcY0z4UC7GDvcG1ERc0LDg5T71qCKEpH0PKxDqE+BbalEeqJoMxbdoK7kqB5lt36RypFobBwUFmCrDPgWOntzlBlhRNaEbSl7KkwulE8VkLZbGHvka4pE+4lPPkQ/Qwml3pHUCk4BxWgN8Z67Bxy2kgXAUiJWUlVAaTLsZm8TmYHFkjpUwUuhqUHLATHQ/tpjq+cOoqZFOyJbiV8cqaBbyFlB6nWQjkwvbB58BiSuNWGg8GGctFYT100n8ypxyWDmD3t4Tx6nAspgU9jOT8yyj2TPf8KVNP+ExwrusftOB9WLahKqM62iLakvUVor9bdYA1I6sQzxGSmEApLyB1BeB2zdUx0atGNk0IBQUqrEFVTzoUq2rB1rBe554YWRcL9EQXZQq4NxGlo5UA5gm5uNj1sdGu1bFyo4nRN0DJCCMLMtalI/Cmpgy93eJndBk4ikFTpj/1W2sdU3/GvM6q+oMBaUgalljzR4YliFrFmm/oAL3kPIoT79H24yGKQT8rlYVrDevOz+RKjUMVJjZD2CHu2/NtEYzAfWpidXMdAH19o9BmRXBjrMCuF5FHJPfXNVwO5hxhJR0NMxLAehDykTgRlaHndu17QulsQSdbH8b1+Jsfpzs61ctiKnzAQX7CX+KG/+5bj1UJaLgywqHqmZVOpExyxuqPGbfpMRHp7W4dRye78bkAYrR7r/bSrE6aH0K4UDrCtKQsrY2do7c2NBYMtSGCu2yQJ/FUU3cgxeZMGW00PPrtWYassewNGAjRc02NJkFLUVtgjORZkntzHBO6Hs3DPnm0LTCveP9xv9ra3f8jsP/Sxvk6jlxU5ToBgWqVtD7SeAPr4foBa/q4aSKL8wTHsTm3vSyoBTv8v8JH14S1eIqlUhv0lDWyvauBHY53IPYL2kodyoPiF+bL3qxJXGQ7LdzX+4fv6GyPZfctlCwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71W247bIBB9z1eglVbaSEvkXJsStf9RVVVFYGzTxWABTrKt9t8rg51gx0madtU3ezzMnDlzmPHk+3Sh+DTdJejXCCEubCnpK0GphMNmhBDTUhuCdtQ8YUzTVCjADg4Ol0YU1Lxi7zCuXXMQWe4IWi5Kf7QOgW1uhHohKKkte8FdTtA0SR7rVypFprBwUFiCrDPgWF7bnaHKCie0ImhL2UtmdKV4yISSycw+I11SJtyrf6uP6B2YVOo9QbngHJSHXhlbY+eQ0kq6AEBKzHKqMiBtjM3obTQ5skDyOpTnop+6x4J3PJYfx/jKqaOYScFe6FbCJ2cq+OZDtpBKLZQD0zk29R59ErfacDDYUC4qW1MXzGd96nDJIESPa7iMHqdCSuDjkK5uGeU1kx3/AlTVfPGOBT3gpp3zVQOqEKq1zYItKm/myyt121gDkjqxgxDMZEJhCakjCK9DtJaJVi26clIoIEhp5U+VlHOhsubYOuQL3HNDsyzi/oSCbCHVBkI3tHKgHEEPD5sONrq1WlbOZ3G6JGgZIHhhptoUJDxK6uDLE14mj56TACRW+qJbSvMa6zt8uqDqGwosJWWQa8kjHZ4pZhZyNqGP8KJ7ECA8x/fhJotePjGXx271800vxouMQmVnOXzYE+zJ8m8DDcGcNzBbuQ6BPl1o9BmRVBjrMMuF5EHJHfVNVz25+zOSDh4xAcO6d2QeOxGUoOV9dz2jZTsgoqiL4bl+Icb5zE61ctiKn9ATn7fneN6dfcvhbD4sFwZYUD3TsirUGY6Q3VHjNl0mAr2dqcOoZE/1bEAYrRblYdzm8d1DaJ8LB9iWlPmRsTe0rM2VBYMtSGCunSQR/FUQ3cAyuUqDLcZHn32jsFWSXIERAfXb9JQSpBSlFfZEjgWZRvsxgvvhItyLa9sC04p3F/fV2g72D5n9hzrW92n0tiKHCRBMq7jsntYjQB/fD1DD310NiZTfW4btqu38SUXA6f8Dfv4DODA8akstkFgo59Vdv5Tbu2r6UVkn0hpWs9w9BlD8RsvZ+2UJ0w37ebwa/hd++w35Qvp/eAsAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../core/src/components/page-list/list.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/list.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VU2W7bMBB811cQAQrYQGlIPRyHQYD+RxEEa3JlbSKRKrU63ML/XoiKD8Y2euRRnOHu7MyKi6cMbqsfqyETvxIhejJcKJGl6Yf7RIi8xEGJ7D7ZJYs98dMlYgWDfHOknWUgi15aqFCJ/f00BnlboxJkS7IoG/qJI7wG/bLxrrVGiQ78TErI85FwBGTtqQK/ldqVzs8jiZ+DRENNXcJWhSn200hDHjWTs0poV7aVHRHvermBWolsWQ9Rqa+XS0FJGyuJsWqU0GgZ/aFDU3iyL0qEQV2HPi9dr0RBxqCNit/Gxa2zGOGrf2r+3DZM+WiIZbQ88SVac0VZDZp4G75Oe343wCBz6JwnxsePYvGUfbEmy7tUFeM44sgNoKEqG/AcDOIPXeIlugtgiG4Mv0BPPIpiHFga1M7DlNGZJ3eqo4YYzcUKERE0U4d/4kEg1K6hqSOsG1e2HPbQ06bgV7/erHeBE7ZM6xDK9RRhcrQpXP/AvsXH0PB/Vvwdu3CEdOub0Qto2UU616dORZJIO3vpP1sr63i26IkL17IMCzB/3YNrtdB7508m+hsf4ju75Nvh+Ti+KmJ28gat0rQe5kHDQexyiqEgg8Gvh5sxjJspjbP8hNglu98dsSOUHQUAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var clearLinkStyle = '_1a7mq8x9';
export var colWrapper = '_1a7mq8x5';
export var deleteIcon = '_1a7mq8xb';
export var editTagWrapper = '_1a7mq8xa';
export var favoriteCell = '_1a7mq8x8';
export var groupsContainer = '_1a7mq8x3';
export var heading = '_1a7mq8x4';
export var hidden = '_1a7mq8x7';
export var hideInSmallContainer = '_1a7mq8x6';
export var listRootContainer = '_1a7mq8x0';
export var pageListScrollContainer = '_1a7mq8x1';
export var root = '_1a7mq8x2';