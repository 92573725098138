import "../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbW/bIBD+nl+BKlVqpBI5r0uJtv8xTdNE4GyzYLAAN+mm/ffJYCfYsZu1qrRv8eW4e+65hztmP+Zb6Q5PIkG/JwhxYUtJXwhKJZx2E4SYltoQ9EzNA8Y0TYUC7ODkcGlEQc0L9g7T2jUHkeWOoPWq9EfrENjmRqgDQUltOQrucoLmSXJff1IpMoWFg8ISZJ0Bx/La7gxVVjihFUF7yg6Z0ZXiIRNKZgv7iHRJmXAv/qs+op/BpFIfCcoF56A89MrYGjuHlFbSBQBSYpZTlQFpY+wmfyazMwskr0N5Lvqpeyx4x3P5cYxvnDqKmRTsQPcSPjtTwXcfsoVUaqEcmM6xuffok7jXhoPBhnJR2Zq6YL7qU4dLBiF6XMM4epwKKYFPQ7q6ZZTXTHb8C1BV8493LOgJN+1cbhpQhVCtbRFsUXkLX16p28YakNSJZwjBTCYUlpA6gvA2RGuZaNWiKyeFAoKUVv5USTkXKmuObUO+wD03NMsi7i8oyB5SbSB0QysHyhF0d7frYKN7q2XlfBanS4LWAYIXZqpNQcJPSR18fcDr5N5zEoDESl91S2k+Y32Hv0ZUfUOBpaQMci15pMMrxSxCzib0GV50DwKEx/g+3GTRyyfm8tytfr75aLzIKFR2lcOHvcCerd8baAjmsoHZynUI9OVCoy+IpMJYh1kuJA9K7qhvvunJ3Z+RdPCICRi2vSPL2Img9dtuekbLdjxEMVfDU30kxvXETrVy2Ipf0JOet+d42Z1863/IFnI4atwurjZpKOxMFkYle6jvP8JosypP0xak7xBCx1w4wLakzI+Fo6Flba4sGGxBAnPttIhAboKwBhbGq8XaYnr2OTYq2iTJKzD8mrzkASlFaYW9MGJBpjH9PX2ser38NAp8dElbYFrx7pp+tco9tTAdlsLHVBnVs32bNm8rcZgIwbSKy+8VFgF6+jhAJzuKZ7Qx0V3orcB2wXbeTxFw+n+BD4yN2lIrJFZKv76ogP2bCvhZWSfSGkGzv306UPxGf9nHZWFaVoXCfuhuhp+7UeIw/ttd03sUtN/vxjVwtSBO2BPTdvBh2bxwror4C1LEb8MgDAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_18ltk9ib';
export var dateCell = '_18ltk9ia';
export var dndCell = '_18ltk9i2';
export var dragPageItemOverlay = '_18ltk9i1';
export var iconCell = '_18ltk9i8';
export var operationsCell = '_18ltk9ic';
export var root = '_18ltk9i0';
export var selectionCell = '_18ltk9i4';
export var tagIndicator = '_18ltk9ie';
export var tagIndicatorWrapper = '_18ltk9id';
export var tagsCell = '_18ltk9i9';
export var titleCell = '_18ltk9i5';
export var titleCellMain = '_18ltk9i6';
export var titleCellPreview = '_18ltk9i7';
export var titleIconsWrapper = '_18ltk9i3';