import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VV62rbMBT+36c4DAYJVMHx0mxzKRQGe4UxRhnH1nGsVZaMJCdOx959WHISx069ZbBfcc71+85Nj8+0zw2WZOH7sn5u1mWzgp83ALnRpf8A0BVmwu0TiO5vAH7dADg9VC2D6tfN4zjgnTce+xzDXco1FXA9jRDAGVQ216ZMwqdER19nLH47B5uhpFm0+Lie/4HNa2GiQ5DlfALj+ynS1yJ8tUBXYFwckH3wkSpthRNaJZCLhnhrJZQl1yFMMXveGF0rzjIttUlgi2bGGOa5UMR66lJzlMHI53phQnFqBg6dNFjP78/wfOPokFmHjh5IOTLEn25hQi/U5smTQCVKDCxO0xvyHv6/m0OuzQ4NtxNZG+GmkjbCTeW8+8ucH/+m8lzYSuI+gVxS0wpQio1iwlFpE8h8AVrxj9o6ke9ZppUj5fqqq1uAHtiJQJTA/ZlgORTEQbAT3BXJgH7k56AgsSncUBfmsRSKXdbHXp/qhlnxItQmgVQbToal2hcj18q1KhqQ8/IULc2PVrsuwSrydZWt2SHpcrFuZRVy7pPEUdVAvKp8jlODDEl0YkvXbYTekpG4ZxUq6m+GZ1Ug17uBb6Wr1qdTdraetUEuapvAMg7QSmyOHDKU2WwZRdsCGLyLq8Y76tq1TBNQWtF5k/+wZ2P91MyvxzOf1anIWEovgswsWqziW4huIVrcfbiF0PdjHJYLKdtJpOTyquD0eo7UU1Df/1+o6WCrMbVa1s4PjdNVAnHXPBP61s7aP21pFp69qQ0o2Hq8AOuLC7C6CwNlNkKxVDuny8Oc9XJyn3OnDWepIXxOwP+wVuIPQCEcMVthRglUhtjOYOXncEsml3rnBedevfDkw49O3ui2tQpGik/cw26XmS/5Kjrsi6fnZVg73co2LZ7QhD6Sha/4Z60dmS/CFZ8KIbkhFbrbD37B19CWjA1sPFYuDGXdEdE71ulf52YdGncWNPfB+gROR+CsZ4d5OrY2it6edWB/4n48eBGsBiQ2l1sxZJNpWZeq9Qwr6Kv28CZHaenN0+ipE217GG1JOXu6SZdcYfAaDX0DhR7g4jrAXeO7Cf8NA3HqDfgKAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var animationTimeout = 'var(--_1ukx6mx3)';
export var closeButton = '_1ukx6mxb';
export var confirmModalContainer = '_1ukx6mxg';
export var confirmModalContent = '_1ukx6mxf';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mxa';
export var modalContentWrapper = '_1ukx6mx9';
export var modalDescription = '_1ukx6mxd';
export var modalFooter = '_1ukx6mxe';
export var modalHeader = '_1ukx6mxc';
export var modalOverlay = '_1ukx6mx8';
export var promptModalContent = '_1ukx6mxh';
export var widthVar = 'var(--_1ukx6mx0)';