import "../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W25KjIBB9z1fwslU7D6TUSTIZ8jVEWmUGwQKMzm7tv2+BRtGYy6TmJVVp4dB9+vSBdSaPDWwj9HeFUAE8LyxBcRRV7WGFEBU8l5hbKA1BKUgL2oUryhiXOUGbfdWieFe1KIncz6bbpk6gM6EaggrOGEgXY9xUgn4RlAnwiz5qY3n2hVMlLUhLkKloCvgItoFuy5Gmn7lWtWQEnaj+jTHNMi4Bjx9wpXlJtUMRSr8cVv9W666i2FeUKWmx4X9ghuDjBd6+HM6Lmr72bRS5mMebbbLQWmwgVZKFRy7UdoW3nFYE7TuOzlwn/f/agMYGBKSWIKkkBLUkvpallHiq5FIiXApf5q18BvjXq/C+4qsUb/y+QQxOBnEvnKPSDDTWlPHaDDU/09A7PWzN1RZOGR6y3vqsH23ZfZUO9cdVey70YS4fq27G5pnkVIm6lNirahPy3uVilOBsxnMHNJ7+XEdK2uKGM1sQ9BqdGhfy1Y1zD0LwynDjPjUFt4A9b07ZjabVpD1Jl7oX7OBA690blL7IWhvHZaX4TLY738g+j9lU7RdluI1+ecoFtNgUmstPgqIA8c0jLrnXd8sbIPePjm6ggziZCPb9CYgkmkDQZc17IhjXkFquJOkFdRhZjaOOsUHj01E6Tg0gCnYm77trd8GwPV3OKnRJn6GjNFTO1HF6+ZRcDurZJDeur+F41t0Q7oRcuwTj6Ti7Gy3q5K5zLgnCY+g8Z51RI6Rq6/Q7Bm6OtSnHfmW05MI7dgGa28A9gkgwjf1Hq6k0FdUg7R2WGSGVoCkUSjDQV7UUrLl0eviufpxDDN04vybGJGltVQCfTeYOt+HkVcrwDl2DoJafYI6fnPFH4QzI+WOJa9U89NzpXzuh07wm88Mfa/6Fr1241eabd8lYNCkcl770S+VMgPzCJYQTN9wCe/JlkK8ZN/Qo4GYmtTRgQy5msih+3OD5zxv8xw0Ti2/50MLb4sKiPu8/Ym88gB7v2381552MBgwAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var addPageButton = 'fnbwe54';
export var arrowDownSmallIcon = 'fnbwe58';
export var buttonText = 'fnbwe5k';
export var docListHeader = 'fnbwe50';
export var docListHeaderTitle = 'fnbwe51';
export var rightButtonGroup = 'fnbwe5j';
export var searchIcon = 'fnbwe59';
export var searchInput = 'fnbwe5d';
export var tagIcon = 'fnbwe5h';
export var tagIndicator = 'fnbwe56';
export var tagLabel = 'fnbwe57';
export var tagSelectorItem = 'fnbwe5g';
export var tagSelectorItemText = 'fnbwe5i';
export var tagSelectorTagsScrollContainer = 'fnbwe5f';
export var tagSticky = 'fnbwe55';
export var tagsEditorRoot = 'fnbwe5a';
export var tagsEditorSelectedTags = 'fnbwe5c';
export var tagsEditorTagsSelector = 'fnbwe5e';
export var tagsMenu = 'fnbwe5b';
export var titleCollectionName = 'fnbwe53';
export var titleIcon = 'fnbwe52';