import "../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61T207CQBB95ys2PMnDmFIBscQvMcYM7bSMlt3NdqBF47+b3SItWqMkPjXdmZ7LntPrp7lu0ryJ1NtIqS1rqDmTTaLiOLLNavQ+uj5uTMPGcTpd+KFSG+JiI917akrjErVHdwWAec6agFOjIQwmfby4ZURXsIaS8h4K1LR+YQG0FhwVbHSitIHMYdFHuAkIFrOMdQGuVbI8Vz0LO7nRAhW/0hdp4bypJp40L6mB2qH1XP55BvOQoSBgKryn+7G4HY0fA/SQY+t4i+4wYHoevsm4siUeksDpubHkQgMLbatEpaSFnD9+3lXCucfRQloSVVlMCdYkNZH2G4WXu2xvLZipj4HMo2j1J+OdtkV3VZ8osxZlyKNQI1BRanQ2bPW2n06iZrZR0/i8NXFf+W/xDLi7QNepaWsjYrZBT1/t8j/VdrB3w3n3utY2zctrTr9eFLVM/XQHpHU0+GMVw3VkXOG6pIGQ8KJeB7Bv5f4ATWlqyUMEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowDownSmallIcon = '_5nxcfx1';
export var currentGroupType = '_5nxcfx6';
export var headerDisplayButton = '_5nxcfx2';
export var listOption = '_5nxcfx7';
export var menu = '_5nxcfx0';
export var properties = '_5nxcfx8';
export var propertiesWrapper = '_5nxcfx9';
export var propertyButton = '_5nxcfxa';
export var subMenuItem = '_5nxcfx4';
export var subMenuTrigger = '_5nxcfx3';
export var subMenuTriggerContent = '_5nxcfx5';