import "../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbW/aMBD+zq+wKlUqUo0CLYwZbf9jmqbJ2JfEq2NHtlPopv73yXYCTghlbJX2jRz38txzT+4y+z7noprvIUO/JghxYWtJXwjKJew3E4SYltoQ9EzNHcY0z4UC7GDvcG1ERc0LDg5T71qCKEpH0PKxDqE+BbalEeqJoMxbdoK7kqB5lt36RypFobBwUFmCrDPgWOntzlBlhRNaEbSl7KkwulE8VkLZbGHvka4pE+4lPPkQ/Qwml3pHUCk4BxWgN8Z67Bxy2kgXAUiJWUlVAaTLsZm8TmYHFkjpUwUuhqUHLATHQ/tpjq+cOoqZFOyJbiV8cqaBbyFlB6nWQjkwvbB58BiSuNWGg8GGctFYT100n8ypxyWDmD3t4Tx6nAspgU9jOT8yyj2TPf8KVNP+ExwrusftOB9WLahKqM62iLakvUVor9bdYA1I6sQzxGSmEApLyB1BeB2zdUx0atGNk0IBQUqrEFVTzoUq2rB1rBe554YWRcL9EQXZQq4NxGlo5UA5gm5uNj1sdGu1bFyo4nRN0DJCCMLMtalI/Cmpgy93eJndBk4ikFTpj/1W2sdU3/GvM6q+oMBaUgalljzR4YliFrFmm/oAL3kPIoT79H24yGKQT8rlYVrDevOz+RKjUMVJjZD2CHu2/NtEYzAfWpidXMdAH19o9BmRXBjrMCuF5FHJPfXNVwO5hxhJR0NMxLAehDykTgRlaHndu17QulsQSdbH8b1+Jsfpzs61ctiKnzAQX7CX+KG/+5bj1UJaLgywqHqmZVOpExyxuqPGbfpMRHp7W4dRye78bkAYrR7r/bSrE6aH0K4UDrCtKQsrY2do7c2NBYMtSGCu2yQJ/FUU3cgxeZMGW00PPrtWYassewNGAjRc02NJkFLUVtgjORZkntzHBO6Hs3DPnm0LTCveP9xv9ra3f8jsP/Sxvk6jlxU5ToBgWqVtD7SeAPr4foBa/q4aSKL8wTHsTm3vSyoBTv8v8JH14S1eIqlUhv0lDWyvauBHY53IPYL2kodyoPiF+bL3qxJXGQ7LdzX+4fv6GyPZfctlCwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1dim1xeb';
export var dateCell = '_1dim1xea';
export var dndCell = '_1dim1xe2';
export var dragPageItemOverlay = '_1dim1xe1';
export var iconCell = '_1dim1xe8';
export var operationsCell = '_1dim1xec';
export var root = '_1dim1xe0';
export var selectionCell = '_1dim1xe4';
export var tagsCell = '_1dim1xe9';
export var titleCell = '_1dim1xe5';
export var titleCellMain = '_1dim1xe6';
export var titleCellPreview = '_1dim1xe7';
export var titleIconsWrapper = '_1dim1xe3';