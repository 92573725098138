import "../component/src/ui/avatar/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/avatar/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW247aMBB936+wVFViJUxzIxfvS/+kMokD0zU2sh1YWu2/V3YSEgea7m4XIRGMJ+fMmTOe5PszO9eK7plGP8IgjGqlI/T7AaHgq7sgZOSBIJwkX5/ckrPaEITDsFsbRYWupdoTtKdGwcsCB6uoWCIcrIp0iYJVkbhFvF6iYImCR3vf6wNCYdpT9Jh5j+ko17MUWexQ08JSpC1Flk0o4mhCkXkMSTDHUGQONIotQ5i2iooJQ5JPRaw9iriYo8hzi53krRT3nU8I0uTeEvLg7j6Ewb3b6fXh+3Unx34nd1xxcHjxLBrWt8g7d1qrLk7Nd3KcTSiyWYq8dWYdWYq4Exv9q5ejiY5wTkfoQIPIXcLojc0cTXRE8zI+0M2fL+Kqne9hxqih79RVrw+rvo0Tx3SCyuyIY7YROwbbnRnWB6nBgBQEKcapgSOz/8ojUzWXJ4J2UFVMPI1x12PcPPZg83CCSjda8sY41ItkF9PrbRcfECsPtARzJnbTrmvghimCNrxRiyNVC4z7lMPHR48FSwVbEASVTBimuosNoQL2tE19GAexRoxqhkFg2RhUS3WiqtIIRA0CWnGXG/GB0zPWhhpG0IE2mlVe+VJXvtvhqhECxNaLz8blLnKv3EU2U25vZPoD82Pj8i4FPwHnuNxRsWXEZbm0iS+HW//iSvS5ruTvdKXwD1fi25LM2KLamJvH4D8q7DmZj51sn7Tx+OH6T19GSqlTentOVKBtoQgCwUEwXHPmppe9Yr1TIJ4JCjy4jYMbdAQENUIz8zSU0xfaZtyX9tZeLYXBGn4xgkrKy8U0Bn1D0aOXbp8n5bAVGAzbazLqyZ+NNlCfcSmFYcKMt45MGSgpx+5WgvZQVdzVotFMYc04Kw1BQgr2wfFavmVsy81PVhpcg03OwnkQ1ZsgrpL4lAJtaPm8VbIRFS4ll6q3jNa17ZCDgj1V53bPmXIr6rQDw9yua6tL6he3T90/68DvLva+Z974XF5pnxH5l7Jca1fbDV2kwRKl4RKl7n167XT9wiAq9tJpmq/BMBUuZ7fLv9tBq3CtHU6jtAU6SLg6x4zsrOWuQBfE0AupJ0f91tjK2/cT78XuCBo2wB3i0ExjiWOSUR5DwQj6Uqf248+eLnaS4ZjP/bYn0BtZ72D7AzmZLGjLDgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var DefaultAvatarBottomItemStyle = '_1012frs7';
export var DefaultAvatarBottomItemWithAnimationStyle = '_1012frs8';
export var DefaultAvatarContainerStyle = '_1012frs4';
export var DefaultAvatarMiddleItemStyle = '_1012frs5';
export var DefaultAvatarMiddleItemWithAnimationStyle = '_1012frs6';
export var DefaultAvatarTopItemStyle = '_1012frs9';
export var avatarFallback = '_1012frsd';
export var avatarImage = '_1012frsc';
export var avatarRoot = '_1012frsa';
export var avatarWrapper = '_1012frsb';
export var blurVar = 'var(--_1012frs1)';
export var hoverWrapper = '_1012frse';
export var removeButton = '_1012frsf';
export var sizeVar = 'var(--_1012frs0)';